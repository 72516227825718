import { Analytics } from '@genoa/analytics'
import { EMBED_SIMPLIFIED_PAYMENTS as content, replaceContent } from '@genoa/screen-content'
import { capitalizeFirstLetter, toFormattedDollars } from '@genoa/utils'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useEmbedAnalytics, useSecureLineOfCredit } from '../../../../../../hooks'
import { useAnalytics } from '../../../../../../providers'
import { CardIcon } from '../../../../../assets'
import { Gap, Headline2, PrimaryButton, SmallText } from '../../../../../components'
import { PaymentScheduleItemProps } from '../../../../../components/PaymentSchedule/PaymentScheduleItem'
import { BasePageLayout } from '../../../../../layouts'
import { PaymentBox, PaymentRow } from './PaymentBox'

interface SimplifiedEpisodicProps {
  cardLast4Digits: string
  cardType: string
  disabled: boolean
  downPaymentAmount: string | undefined
  handlePayButtonPress: () => void
  loading: boolean
  propertyName: string
  secondPayment: PaymentScheduleItemProps | undefined
  totalAmount: number
}

export const SimplifiedEpisodic = (props: SimplifiedEpisodicProps) => {
  const { isEnabledForSLC } = useSecureLineOfCredit()
  const { embedProperties } = useEmbedAnalytics()

  const Card = () => (
    <CardContainer>
      <CardIcon />
      <CardInfo>{`${capitalizeFirstLetter(props.cardType)} card x${props.cardLast4Digits}`}</CardInfo>
    </CardContainer>
  )

  const firstPayment: PaymentRow[] = [
    { leftSide: content.FIRST_PAYMENT_DUE, rightSide: props.downPaymentAmount },
    { leftSide: <Card /> },
  ]

  const payTo: PaymentRow[] = [
    { leftSide: content.FLEX_RENT_PAY_TO, rightSide: props.propertyName },
    { leftSide: content.FLEX_RENT_AMOUNT, rightSide: toFormattedDollars(props.totalAmount) },
  ]

  // Remove day of week like "Monday, " from date "Monday, Aug 15"
  const secondDueDate = props.secondPayment?.lineItems?.[0]?.label?.replace(/^\w+,\s(?=.+)/, '') || ''
  const secondPayment: PaymentRow[] = [
    {
      leftSide: replaceContent(content.SECOND_PAYMENT_DUE, { dueDate: secondDueDate }),
      rightSide: String(props.secondPayment?.amount || ''),
    },
    { leftSide: <Card /> },
  ]

  const firstPaymentHeader = replaceContent(content.FIRST_PAYMENT_HEADER, {
    paymentType: isEnabledForSLC ? 'deposit' : '1st payment',
  })

  return (
    <BasePageLayout
      analyticsScreenName={Analytics.Screens.EMBED_NON_SLC_PAY_RENT}
      analyticsScreenParams={embedProperties}
    >
      <Container flex={1} flexDirection="column" px="md">
        <Gap size="spacing_200" />

        <Headline2>{content.HEADER}</Headline2>
        <Gap size="spacing_100" />

        <SmallText>{content.BODY}</SmallText>
        <Gap size="spacing_200" />

        <PaymentBox header={firstPaymentHeader} rows={firstPayment} />
        <Gap size="spacing_150" />
        <PaymentBox header={content.FLEX_RENT_HEADER} rows={payTo} />
        <Gap size="spacing_150" />
        <PaymentBox
          header={replaceContent(content.SECOND_PAYMENT_HEADER, { dueDate: secondDueDate })}
          rows={secondPayment}
        />
        <Gap size="spacing_150" />

        <Box>
          <PrimaryButton
            disabled={props.disabled}
            onClick={props.handlePayButtonPress}
            testID="ManualPayPayNowCTAButton"
            processing={props.loading}
          >
            {content.CTA}
          </PrimaryButton>
          <Gap size="spacing_225" />
        </Box>
      </Container>
    </BasePageLayout>
  )
}

const Container = styled(Box)`
  height: 100%;
`

const CardContainer = styled(Flex)`
  align-items: center;
  flex-direction: row;
`

const CardInfo = styled(SmallText)`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  margin-left: ${({ theme }) => theme.fixedSizes.spacing_100};
`
