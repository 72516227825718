import { Navigate, Route, Routes as RouterRoutes } from 'react-router-dom'

import { useIsEmbed } from '../hooks'
import { useUserAccountState } from '../modules/flex2/use-state'
import {
  EmbedAlreadyFlexingYourRent,
  EmbedBuildCreditInApp,
  EmbedCongrats,
  EmbedCreditLineDeclined,
  EmbedCreditLineSuspended,
  EmbedNotRentDay,
  EmbedPropertyMismatch,
  EmbedRentSplitProgress,
  EmbedServiceIssue,
  EmbedSomethingWentWrong,
  ManualPayFinishContainer,
  RelinkAcceptanceOfferDetailsContainer,
  RelinkDirectIntegrationConfirmAddressContainer,
  RelinkRentAmountContainer,
  RelinkVerifyIdentityContainer,
  SimplifiedEpisodicContainer,
} from '../views/pages/common/embed'
import * as Routes from './constants'
import { getRouteSlugs } from './utils'

const routeSlugs = getRouteSlugs(Routes.App.EMBED, Routes.Embed)

export function EmbedRoutes() {
  const userAccountState = useUserAccountState()
  const isEmbed = useIsEmbed()
  if (!userAccountState.loaded || !userAccountState.initialized || !isEmbed) {
    return <Navigate to={Routes.Onboarding.CREATE_ACCOUNT} replace />
  }

  return (
    <RouterRoutes>
      <Route path={routeSlugs.CONGRATS} element={<EmbedCongrats />} />

      <Route
        path={routeSlugs.RELINK_DIRECT_INTEGRATION_CONFIRMATION}
        element={<RelinkDirectIntegrationConfirmAddressContainer />}
      />
      <Route path={routeSlugs.RELINK_RENT_AMOUNT} element={<RelinkRentAmountContainer />} />
      <Route path={routeSlugs.RELINK_VERIFY_IDENTITY} element={<RelinkVerifyIdentityContainer />} />
      <Route path={routeSlugs.RELINK_OFFER_DETAILS} element={<RelinkAcceptanceOfferDetailsContainer />} />

      <Route path={routeSlugs.SIMPLIFIED_EPISODIC} element={<SimplifiedEpisodicContainer />} />
      <Route path={routeSlugs.MANUAL_PAY_FINISH} element={<ManualPayFinishContainer />} />

      <Route path={routeSlugs.ALREADY_FLEXING_YOUR_RENT} element={<EmbedAlreadyFlexingYourRent />} />
      <Route path={routeSlugs.BUILD_CREDIT_IN_APP} element={<EmbedBuildCreditInApp />} />
      <Route path={routeSlugs.NOT_RENT_DAY} element={<EmbedNotRentDay />} />
      <Route path={routeSlugs.RENT_SPLIT_PROGRESS} element={<EmbedRentSplitProgress />} />
      <Route path={routeSlugs.SERVICE_ISSUE} element={<EmbedServiceIssue />} />
      <Route path={routeSlugs.SOMETHING_WENT_WRONG} element={<EmbedSomethingWentWrong />} />
      <Route path={routeSlugs.PROPERTY_MISMATCH} element={<EmbedPropertyMismatch />} />
      <Route path={routeSlugs.CREDIT_LINE_DECLINED} element={<EmbedCreditLineDeclined />} />
      <Route path={routeSlugs.CREDIT_LINE_SUSPENDED} element={<EmbedCreditLineSuspended />} />
    </RouterRoutes>
  )
}
