import { Box } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { RadioCheckPurple } from '../../assets'

interface RadioCircleProps {
  isSelected: boolean
}

interface ActiveIconProps extends RadioCircleProps {
  style?: 'circle' | 'checkmark'
}

interface RadioButtonProps extends ActiveIconProps {
  onClick: () => void
  testID?: string
}

export const RadioButton = (props: RadioButtonProps) => (
  <RadioCircle testID={props.testID} onClick={props.onClick} isSelected={props.isSelected}>
    <ActiveIcon isSelected={props.isSelected} style={props.style} />
  </RadioCircle>
)

const ActiveIcon = (props: ActiveIconProps) => {
  if (props.style === 'checkmark') {
    return props.isSelected ? <RadioCheckPurple /> : null
  }

  return <Dot />
}

const RadioCircle = styled(Box)<RadioCircleProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: ${(props) => props.theme.fixedSizes.spacing_150};
  min-height: ${(props) => props.theme.fixedSizes.spacing_150};
  width: ${(props) => props.theme.fixedSizes.spacing_150};
  height: ${(props) => props.theme.fixedSizes.spacing_150};
  background-color: ${(props) => (props.isSelected ? props.theme.colors.jewelPurple : props.theme.colors.winterWhite)};
  border: 1px solid ${(props) => props.theme.colors.storm};
  border-radius: 50%;

  cursor: pointer;
`

const Dot = styled(Box)`
  width: ${(props) => props.theme.fixedSizes.spacing_37_5};
  height: ${(props) => props.theme.fixedSizes.spacing_37_5};
  background-color: ${(props) => props.theme.colors.winterWhite};
  border-radius: 1000px;
`
