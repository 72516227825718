import { createReducer } from '@reduxjs/toolkit'

import * as Actions from './actions'
import { Embed } from './types'

export const initialState: Embed = {
  client: '',
  isEmbed: false,
  token: '',
}

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions.resetEmbedAction, () => {
      return { ...initialState }
    })
    .addCase(Actions.setEmbedAction, (_state, action) => {
      return { ...action.payload }
    })
})
