import { IntegrationType } from '@genoa/domain'

import { useAccount } from '../../hooks/use-account'
import { integrationTypeToTrackingValue } from './mappers'
import { filterEmptyValues, mapValuesToHashedSha256, useOffer, useProperty, useWalletCard } from './util'

type EmptyOr<T> = {} | T

type CustomerInfo = EmptyOr<{
  customerId: string
  // firstName: string
  // lastName: string
  email: string
}>

type BillerInfo = EmptyOr<{
  propName: string
  integrationType: string
}>

type IntegrationInfo = EmptyOr<
  {
    propName: string
    pmcName: string
    integrationType: string
  } & Partial<{
    currentTier: string
    qualifiedTier: string
  }>
>

type UnderwritingInfo = EmptyOr<{
  rentAmount: string
  productType: string
}>

type WalletInfo = EmptyOr<{
  cardNetwork: string
}>

type AggregateUserInfo = CustomerInfo & BillerInfo & IntegrationInfo & UnderwritingInfo & WalletInfo

const useCustomerInformation = (): CustomerInfo => {
  const { customer } = useAccount()
  if (customer === undefined) {
    return {}
  }

  const { public_id: customerId, email } = customer

  return {
    customerId,
    ...mapValuesToHashedSha256({
      // firstName,
      // lastName,
      email,
    }),
  }
}

const useBillerInformation = (): BillerInfo => {
  const { billerConnection } = useAccount()
  if (billerConnection === undefined || billerConnection.biller === undefined) {
    return {}
  }

  const { name, type, system } = billerConnection.biller

  const integrationType = (() => {
    if (type === IntegrationType[IntegrationType.DIRECT_INTEGRATION]) {
      if (system !== undefined && system !== null) {
        return `${type}_${system.toUpperCase()}`
      }
      return type
    } else if (
      type === IntegrationType[IntegrationType.PORTAL] ||
      type === IntegrationType[IntegrationType.FLEX_ANYWHERE]
    ) {
      return type
    }
    return ''
  })()

  const sanitizedName = (() => {
    if (name.endsWith(' [Generic Portal]')) {
      return name.replace(' [Generic Portal]', '')
    }
    return name
  })()

  return {
    propName: sanitizedName,
    integrationType,
  }
}

const useIntegrationInformation = (): IntegrationInfo => {
  const property = useProperty()
  if (property === undefined) {
    return {}
  }

  const {
    pmc_name: pmcName,
    name: propName,
    integration_type: integrationType,
    billing_integration_type: billingIntegrationType,
    current_tier: currentTier,
    qualified_tier: qualifiedTier,
  } = property

  return filterEmptyValues({
    propName,
    pmcName,
    integrationType: integrationTypeToTrackingValue(integrationType, billingIntegrationType),
    currentTier,
    qualifiedTier,
  })
}

const useUnderwritingInformation = (): UnderwritingInfo => {
  const offer = useOffer()
  if (offer === undefined) {
    return {}
  }

  const { estimated_bill_amount_cent } = offer
  const rentAmount = (estimated_bill_amount_cent / 100).toFixed(2)
  return { rentAmount }
}

const useWalletInformation = (): WalletInfo => {
  const walletCard = useWalletCard()
  if (walletCard === undefined) {
    return {}
  }

  const { brand: cardNetwork } = walletCard
  return { cardNetwork }
}

export const useAggregateUserInformation = (): AggregateUserInfo => {
  return {
    ...useCustomerInformation(),
    ...useBillerInformation(),
    ...useIntegrationInformation(),
    ...useUnderwritingInformation(),
    ...useWalletInformation(),
  }
}
