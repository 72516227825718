import { Analytics } from '@genoa/analytics'
import { CO_CONNECT_CARD, CONNECT_CARD, deepReplaceContent } from '@genoa/screen-content'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useContentOverhaul } from '../../../../../../hooks'
import { ExtraSmallText, Headline2, PrimaryButton, SmallText } from '../../../../../components'
import { BasePageLayout } from '../../../../../layouts'

type ConnectCardProps = {
  analyticsScreenName: Analytics.Screens
  onClickAddCardDetails: () => unknown
  processingFeePercentage: number
  debitProcessingFee: number
  creditProcessingFee: number
}

export const V2ConnectCard = (props: ConnectCardProps) => {
  const { onboardingEnabled } = useContentOverhaul()
  const content = deepReplaceContent(onboardingEnabled ? CO_CONNECT_CARD : CONNECT_CARD, {
    debitProcessingFee: String(props.debitProcessingFee),
    creditProcessingFee: String(props.creditProcessingFee),
  })

  return (
    <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
      <Box minH="30px" px="lg" />
      <Container>
        <Headline2>{content.HEADER}</Headline2>
        <Box minH="30px" px="lg" />

        <Box data-testid="CFPInformation">
          <SmallText>{content.BODY_LINE_1}</SmallText>
          <Box minH="20px" px="lg" />
          <SmallText>{content.BODY_LINE_2}</SmallText>
          <Box minH="20px" px="lg" />
          <SmallText>{content.BODY_LINE_3}</SmallText>
          <Box minH="30px" px="lg" />
        </Box>
        <Box minH="30px" px="lg" />
        <PrimaryButton
          processing={false}
          disabled={false}
          onClick={props.onClickAddCardDetails}
          testID="ConnectCardAddCardDetailsButton"
        >
          {content.CTA}
        </PrimaryButton>
      </Container>
    </BasePageLayout>
  )
}

const Container = styled(Box)`
  height: 100%;
`

const IconWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  margin-right: 9px;
`

const DisclaimerText = styled(ExtraSmallText)`
  padding-top: 2px;
`
