import { Analytics } from '@genoa/analytics'
import cleanLogData from '@genoa/utils/lib/clean-log-data'
import { datadogLogs } from '@datadog/browser-logs'
import { getAnalytics, logEvent, setUserId } from 'firebase/analytics'

import { useAmplitude } from '../amplitude'
import { useFirebase } from '../firebase/firebase-provider'
import { logger } from '../logger'
import { retainEnhancedTrackingProperties, retainHashedEmail } from './util'

type AnalyticsService = {
  logEvent: (name: Analytics.Events, data?: any) => void
  logScreenView: (screenName: Analytics.Screens, data?: any) => void
  setUserId: (id: string) => void
  setDeviceId: (id: string) => void
  setUserProperty: (name: Analytics.UserProperties, propertyValue: any, setOnce?: boolean) => void
}

export const USER_SIGNUP_STATUS = 'flexapp/user_signup_status'
export const USER_ONBOARDING_STATUS = 'flexapp/user_onboarding_status'

export enum OnboardingStatus {
  None = 'none',
  Onboarding = 'onboarding',
  Active = 'active',
}
export enum SignupStatus {
  None = 'none',
  SignedUp = 'signed_up',
}

export const setUserSignupStatus = () => {
  try {
    return localStorage.setItem(USER_SIGNUP_STATUS, SignupStatus.SignedUp)
  } catch (err: any) {
    logger.error('Analytics - setUserSignupStatus', `${JSON.stringify(err)}`)
  }
}

export const setOnboardingStatus = (status: OnboardingStatus) => {
  try {
    return localStorage.setItem(USER_ONBOARDING_STATUS, status)
  } catch (err: any) {
    logger.error('Analytics - setOnboardingStatus', `${JSON.stringify(err)}`)
  }
}

export const useAnalytics = (): AnalyticsService => {
  const firebaseApp = useFirebase()
  const firebaseAnalytics = getAnalytics(firebaseApp)
  const { logEvent: logAmplitudeEvent, setDeviceId, setUserProperty: setAmplitudeUserProperty } = useAmplitude()

  return {
    logEvent: (event: Analytics.Events, data?: any) => {
      const newData = retainEnhancedTrackingProperties(event, data, retainHashedEmail(data, cleanLogData(data)))
      logAmplitudeEvent(event, newData)
      logEvent(firebaseAnalytics, event, newData)
      logger.log('Analytics', `EVENT: ${event} --- ${JSON.stringify(newData)}`)
      datadogLogs.logger.info(`[Analytics] EVENT: ${event}`, { content: newData })
    },

    logScreenView: (screenName: Analytics.Screens, data?: any) => {
      logAmplitudeEvent(screenName, data)
      logEvent(firebaseAnalytics, screenName)
      logger.log('Analytics', `SCREEN: ${screenName}`)
      datadogLogs.logger.info(`[Analytics] SCREEN: ${screenName}`)
    },

    setUserId: (id: string) => {
      setUserId(firebaseAnalytics, id)
    },

    setUserProperty: (name: Analytics.UserProperties, propertyValue: any, setOnce?: boolean) => {
      setAmplitudeUserProperty(name, propertyValue, setOnce)
    },

    setDeviceId: (id: string) => {
      setDeviceId(id)
    },
  }
}

export default { useAnalytics }
