import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFindUserAccount } from '@genoa/middle-end'
import { Box } from '@chakra-ui/react'

import { useReduxAction } from '../../../hooks'
import { RootState } from '../../../modules'
import { setPhoneNumberAction } from '../../../modules/onboarding'
import { useNavigation } from '../../../navigation'
import { Config } from '../../../providers'
import { DevAuth } from './DevAuth'

export const DevAuthContainer = () => {
  const { navigateToLogIn } = useNavigation()
  const setPhoneNumber = useReduxAction(setPhoneNumberAction)
  const phoneNumber = useSelector((state: RootState) => state.onboarding.phoneNumber)
  const [shouldResetAccount, setShouldResetAccount] = useState(false)

  const [{ loading: loadingFindAccount }] = useFindUserAccount()

  useEffect(() => {
    if (Config.ENVIRONMENT === 'production') {
      navigateToLogIn()
    }
  }, [])

  const handleNext = async () => {
    // TODO: Connect to new firebase auth api when it's ready
    console.log('resetAccount >>', shouldResetAccount)
  }
  const handleCheckbox = () => {
    setShouldResetAccount(!shouldResetAccount)
  }

  return (
    <Box>
      <DevAuth
        phone={phoneNumber.extracted}
        onPhoneChange={setPhoneNumber}
        onNextClick={handleNext}
        loading={loadingFindAccount}
        shouldResetAccount={shouldResetAccount}
        onCheck={handleCheckbox}
      />
    </Box>
  )
}
