import { Analytics } from '@genoa/analytics'
import { DEV_AUTH as content } from '@genoa/screen-content'
import { Box, Checkbox, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useEmbedFlow } from '../../../hooks'
import { PhoneNumberState } from '../../../modules'
import { Gap, Headline2, PrimaryButton, SmallText } from '../../components'
import { PhoneInput } from '../../components/Input'
import { BasePageLayout } from '../../layouts'

type DevAuthProps = {
  phone: string
  onPhoneChange: (phoneNumber: PhoneNumberState) => unknown
  onNextClick: () => unknown
  shouldResetAccount: boolean
  onCheck: () => void
  loading?: boolean
  enableDevMenu?: boolean
}

const PHONE_NUMBER_DIGITS = 10
const removeMask = (phone: string) => phone.replace(/\D/g, '').substring(0, PHONE_NUMBER_DIGITS)
const maskPhone = (phone: string) => {
  return removeMask(phone)
    .replace(/(\d{3})(\d)/, '($1) $2')
    .replace(/(\d{3})(\d)/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1')
}

export const DevAuth = (props: DevAuthProps) => {
  const unmaskedPhone = removeMask(props.phone)
  const formattedPhone = maskPhone(unmaskedPhone)
  const embedFlow = useEmbedFlow()

  const handlePhoneChange = (newPhone: string) => {
    const unmaskedNewPhone = removeMask(newPhone)

    if (unmaskedPhone !== unmaskedNewPhone) {
      const formattedNewPhone = maskPhone(unmaskedNewPhone)
      props.onPhoneChange({
        extracted: unmaskedNewPhone,
        formatted: formattedNewPhone,
      })
    }
  }

  return (
    <BasePageLayout analyticsScreenName={Analytics.Screens.SIGNIN_PHONE_NUMBER} analyticsScreenParams={{ embedFlow }}>
      <Gap size="spacing_200" />

      <Headline2 data-testid="PhoneNumberHeading">{content.HEADING}</Headline2>

      <Gap size="spacing_200" />

      <SmallText data-testid="PhoneNumberLabel">{content.PHONE_LABEL}</SmallText>

      <Gap size="spacing_50" />

      <PhoneInput
        phone={formattedPhone}
        onPhoneChange={handlePhoneChange}
        testID="DevAuthPhoneInput"
        autoComplete="phone"
      />

      <Gap size="spacing_150" />
      <CheckboxContainer>
        <StyledCheckBox
          isChecked={props.shouldResetAccount}
          onChange={props.onCheck}
          data-testid="ResetAccountCheckBox"
        />
        <SmallText data-testid="ResetAccountCheckBoxLabel">{content.RESET_ACCOUNT}</SmallText>
      </CheckboxContainer>

      <Box>
        <Gap size="spacing_300" />

        <PrimaryButton
          disabled={unmaskedPhone.length !== 10 || props.loading}
          processing={props.loading}
          onClick={props.onNextClick}
          testID="DevAuthNextButton"
        >
          {content.SUBMIT_CTA}
        </PrimaryButton>
      </Box>
    </BasePageLayout>
  )
}

const CheckboxContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  gap: ${(props) => props.theme.fixedSizes.spacing_50};
`

const StyledCheckBox = styled(Checkbox)`
  > span {
    margin-top: 0px;
  }
`
