import { useEffect } from 'react'
import { resetPrefillsAction, resetPropertyLinkingAction } from '@genoa/state-management'
import { isInIframe } from '@genoa/utils'

import { useEmbed } from '../../hooks'
import { resetEmbedAction, setEmbedAction } from '../../modules/flex2/embed'
import { EmbedType } from '../../modules/flex2/embed'
import { Config } from '../../providers'
import { useReduxAction } from '../use-redux-action'

export const useSetEmbedParams = () => {
  const setEmbed = useReduxAction(setEmbedAction)
  const resetEmbed = useReduxAction<void>(resetEmbedAction)
  const resetPropertyLinking = useReduxAction<void>(resetPropertyLinkingAction)
  const resetPrefills = useReduxAction<void>(resetPrefillsAction)
  const embed = useEmbed()
  const inIframe = isInIframe()

  const checkIfOriginWhitelisted = (origin: string): boolean => {
    const isWhitelistOrigin = !!Config.EMBED_ORIGINS_WHITELIST?.some((item: any) =>
      new RegExp(`https?:\/\/(www.)?[^/]*${item}$`).test(origin)
    )
    const isFlexOrigin = /https?:\/\/[^/]*getflex.com$/.test(origin)
    const isLocalhost = /https?:\/\/[^/]*localhost:3001$/.test(origin)

    return isWhitelistOrigin || isFlexOrigin || isLocalhost
  }

  useEffect(() => {
    const { client, isEmbed, token } = getEmbedParams()
    const hasEmbedParams = !!(isEmbed && client && token)
    const {
      location: { pathname },
    } = window
    const isRoot = pathname === '/' || pathname === '/auth/phone'

    if (hasEmbedParams) {
      setEmbed({ client, isEmbed, token })
      resetPrefills()
    } else if (embed.isEmbed && isRoot && !inIframe) {
      resetEmbed()
      resetPropertyLinking()
      resetPrefills()
    }

    const onMessage = (event: MessageEvent) => {
      const { data = {}, origin } = event
      const isOriginWhiteWhitelisted = checkIfOriginWhitelisted(origin)
      if (hasEmbedParams && isOriginWhiteWhitelisted) {
        const type = JSON.parse(data).type || EmbedType.modal
        setEmbed({ client, isEmbed, origin, token, type })
      }
    }

    if (hasEmbedParams && inIframe) {
      window.addEventListener('message', onMessage)
    }

    return () => {
      if (hasEmbedParams && inIframe) {
        window.removeEventListener('message', onMessage)
      }
    }
  }, [])
}

export const getEmbedParams = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const isEmbed = urlParams.get('e') === '1'
  const client = urlParams.get('client')
  const token = urlParams.get('token')

  return { client, isEmbed, token }
}
