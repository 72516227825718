import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { configureFlex2API } from '@genoa/middle-end'
import { getAuth, signInAnonymously, User } from 'firebase/auth'

import { useReduxAction, useReduxSelector } from '../hooks'
import { RootState } from '../modules'
import { firebaseInitializedAction } from '../modules/auth'
import { Config, logger, useFirebase } from '../providers'

export type AuthenticationContextData = {
  user: User | null
  authenticated: boolean
  isAnonymous: boolean
  isOnboarding: boolean
  token: string | null
  uid: string | null
  setUser: (user: User | null) => void
  forceRefreshToken: () => any
  setProcessingLogin: (processing: boolean) => any
  processingLogin: boolean
  tokenIsReady: boolean
  setTokenIsReady: (value: boolean) => any
}

export const AuthenticationContext = React.createContext<AuthenticationContextData>({
  user: null,
  authenticated: false,
  isAnonymous: true,
  isOnboarding: true,
  token: null,
  uid: null,
  setUser: () => {},
  forceRefreshToken: () => {},
  setProcessingLogin: () => {},
  processingLogin: false,
  tokenIsReady: false,
  setTokenIsReady: (value: boolean) => {},
})

type AuthenticationProviderProps = {
  children?: ReactNode
}

export const AuthenticationProvider = ({ children }: AuthenticationProviderProps) => {
  const [user, setUser] = useState<User | null>(null)
  const [processingLogin, setProcessingLogin] = useState(false)
  const [token, setToken] = useState<string | null>(null)
  const [tokenIsReady, setTokenIsReady] = useState(false)
  const firebaseInitialized = useReduxAction(firebaseInitializedAction)
  const firebaseApp = useFirebase()
  const deviceMetadata = useReduxSelector((state: RootState) => state.deviceMetadata)

  const additionalConfig = {
    versionNumber: Config.VITE_BUILD_VERSION || '0.0.0',
    platform: 'web',
    deviceId: deviceMetadata?.deviceId,
  }

  useEffect(() => {
    const auth = getAuth(firebaseApp)

    const queryParams = new URLSearchParams(window.location.search)
    if (Config.E2E && queryParams.get('e2e')) {
      auth.settings.appVerificationDisabledForTesting = true
    }

    const unsubscribe = auth.onAuthStateChanged((newUser: User | null) => {
      if (newUser && newUser?.isAnonymous) {
        logger.log('Authentication', 'User signed-in anonymously')
      }

      if (newUser && newUser.uid) {
        logger.log('Authentication', `User signed-in as: ${newUser.uid}`)
        setUser(newUser)
        firebaseInitialized(true)
      } else {
        setUser(null)

        signInAnonymously(auth).catch((error) => {
          if (error.code === 'auth/operation-not-allowed') {
            logger.log('Authentication', 'Need to enable anonymous sign-in in Firebase.')
          }

          logger.error('Authentication', error)
        })
      }
    })

    return () => {
      unsubscribe()
    }
  }, [])

  const forceRefreshToken = useCallback(() => {
    if (!user) {
      return
    }

    setTokenIsReady(false)
    return user.getIdToken(true).then((newToken: string) => {
      logger.log('Authentication', 'Force refresh was used to generate a new token')
      setToken(newToken)
      return newToken
    })
  }, [user])

  const getCurrentToken = useCallback(async () => {
    if (!user) {
      return
    }

    return user.getIdToken().then((newToken: string) => {
      return newToken
    })
  }, [user])

  useEffect(() => {
    if (!user) {
      return
    }

    setTokenIsReady(false)
    user.getIdToken().then((newToken: string) => {
      configureFlex2API(
        Config.FLEX_2_API,
        newToken,
        forceRefreshToken,
        Config.ENVIRONMENT === 'local',
        Config.MOCK_STATE,
        getCurrentToken,
        additionalConfig
      )
      setTimeout(() => {
        setTokenIsReady(true)
      }, 0)
      setToken(newToken)
    })
  }, [user, forceRefreshToken, getCurrentToken])

  useEffect(() => {
    if (user?.isAnonymous) {
      setTokenIsReady(false)
    }
  }, [user?.isAnonymous])

  const value = useMemo(
    () => ({
      user: user,
      authenticated: !!user,
      isAnonymous: user?.isAnonymous || false,
      isOnboarding: true,
      token,
      uid: user?.uid || null,
      setUser,
      forceRefreshToken,
      setProcessingLogin,
      processingLogin,
      tokenIsReady,
      setTokenIsReady,
    }),
    [user, token, processingLogin, tokenIsReady]
  )

  return <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>
}

export const useAuthState = () => useContext(AuthenticationContext)
