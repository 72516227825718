import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'

import * as Routes from '../../../../routing/constants'
import { DirectIntegrationConfirmAddressContainer } from '../../common/property-linking/direct-integration-confirm-address/DirectIntegrationConfirmAddressContainer'

type OnboardingDirectIntegrationConfirmAddressContainerProps = {}

export const OnboardingDirectIntegrationConfirmAddressContainer = (
  props: OnboardingDirectIntegrationConfirmAddressContainerProps
) => {
  const navigate = useNavigate()

  return (
    <DirectIntegrationConfirmAddressContainer
      {...props}
      analyticsScreenName={Analytics.Screens.ONBOARDING_CONFIRM_ADDRESS}
      analyticsEventBackClicked={Analytics.Events.DIRECT_INTEGRATION_CONFIRM_ADDRESS_BACK_CLICKED}
      analyticsEventCTAClicked={Analytics.Events.DIRECT_INTEGRATION_CONFIRM_ADDRESS_CLICKED}
      analyticsEventStartOverClicked={Analytics.Events.DIRECT_INTEGRATION_CONFIRM_ADDRESS_START_OVER_CLICKED}
      onNext={() => navigate(Routes.Onboarding.RENT_AMOUNT)}
      onStartOver={() => {
        navigate(Routes.Onboarding.ADDRESS)
      }}
    />
  )
}
