import { useLocation } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'

import { useEmbed } from '../../../../../../hooks'
import { EmbedType } from '../../../../../../modules/flex2/embed'
import { useAnalytics } from '../../../../../../providers'
import { ManualPayFinish } from './ManualPayFinish'

export const ManualPayFinishContainer = () => {
  const { type } = useEmbed()
  const analytics = useAnalytics()
  const {
    state: { downPaymentAmount },
  } = useLocation()

  const handleContinue = () => {
    analytics.logEvent(Analytics.Events.MANUAL_PAY_FINISH_CTA_CLICK)
    if (type === EmbedType.modal) {
      window.parent.postMessage({ message: 'Rent has been paid.', type: 'embed-success' }, '*')
    } else {
      window.close()
    }
  }

  return <ManualPayFinish downPaymentAmount={downPaymentAmount} handleContinue={handleContinue} />
}
