import { Agreement } from '@genoa/domain'
import { ListItem, UnorderedList } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'

import { useAgreementLink } from '../../../../hooks'
import { InlineButton, SmallText } from '../../../components'

export interface AgreementsListProps {
  readonly agreements: Agreement[]
}

export function AgreementsList(props: AgreementsListProps) {
  const theme = useTheme()
  const handleClick = useAgreementLink()

  return (
    <UnorderedList>
      {props.agreements.map((a, i) => (
        <ListItem key={i}>
          <SmallText fontWeight="bold">
            <InlineButton color={theme.colors.jewelPurple} onClick={() => handleClick(a)}>
              {a.agreement_type_name}
            </InlineButton>
          </SmallText>
        </ListItem>
      ))}
    </UnorderedList>
  )
}
