import React from 'react'
import { Analytics } from '@genoa/analytics'
import { BillerPropertyUnit } from '@genoa/domain'
import { BillerPropertyDetails } from '@genoa/middle-end'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { Headline1, InlineButton, PrimaryButton, SmallText, Text } from '../../../../components'
import { BasePageLayout } from '../../../../layouts'
import { PrefillConfirmBanner } from '../PrefillConfirmBanner'

type DirectIntegrationConfirmAddressProps = {
  onBack: () => unknown
  onNext: () => unknown
  onStartOver: () => unknown
  property: BillerPropertyDetails
  unit: BillerPropertyUnit
  loading: boolean
  analyticsScreenName: Analytics.Screens
  showPrefills: boolean
  isEmbed: boolean
}

export const DirectIntegrationConfirmAddress = (props: DirectIntegrationConfirmAddressProps) => (
  <BasePageLayout analyticsScreenName={props.analyticsScreenName} analyticsScreenParams={{ property: props.property }}>
    <Box minH={'30px'} />
    <Container flex={1} flexDirection="column" px="lg">
      <Headline1>Confirm your property</Headline1>

      <Box minH={'24px'} />
      {!props.isEmbed && <ConfirmAddressText onStartOver={props.onStartOver} showPrefills={props.showPrefills} />}

      <Box minH={'24px'} />

      <PropertyContainer>
        <Box>
          <SmallTextStyled>Property name</SmallTextStyled>

          <Divider />

          <SmallText
            data-testid="DirectIntegrationConfirmAddressPropertyName"
            textAlign="right"
            data-dd-action-name="Property Name"
          >
            {props.property?.name}
          </SmallText>
        </Box>

        <Box minH={'32px'} />

        <Box>
          <SmallTextStyled>Address</SmallTextStyled>

          <Divider />

          <Flex data-dd-action-name="Address">
            {!!props.unit && (
              <Flex flexDirection="column">
                {props.unit.address_line_1 && (
                  <SmallText data-testid="DirectIntegrationConfirmAddressAddressLine1">
                    {props.unit.address_line_1}
                  </SmallText>
                )}
                {props.unit.address_line_2 && (
                  <SmallText data-testid="DirectIntegrationConfirmAddressAddressLine2">
                    {props.unit.address_line_2}
                  </SmallText>
                )}
                <SmallText data-testid="DirectIntegrationConfirmAddressAddressLine3">
                  {props.unit.city}
                  {props.unit.state ? `, ` + props.unit.state : ''}
                  {props.unit.postal_code ? ` ` + props.unit.postal_code : ''}
                </SmallText>
              </Flex>
            )}
          </Flex>
        </Box>

        <Box minH={'32px'} />

        <Box>
          <SmallTextStyled>Unit code</SmallTextStyled>

          <Divider />

          <SmallText data-testid="DirectIntegrationConfirmAddressUnitCode" data-dd-action-name="Property Name">
            {props.unit?.label}
          </SmallText>
        </Box>
      </PropertyContainer>

      <Box minH={'64px'} />

      {!props.showPrefills && !props.isEmbed && (
        <Box display={'flex'} justifyContent={'center'}>
          <SmallText>
            <InlineButton
              disabled={props.loading}
              onClick={props.onStartOver}
              testID="DirectIntegrationConfirmAddressStartOverButton"
            >
              Not right? <b>Start over with a new address</b>
            </InlineButton>
          </SmallText>
        </Box>
      )}
      <Box minH={'24px'} />
      <Box>
        <PrimaryButton
          disabled={props.loading}
          processing={props.loading}
          testID="DirectIntegrationConfirmAddressNext"
          onClick={props.onNext}
        >
          Continue
        </PrimaryButton>
      </Box>
    </Container>
  </BasePageLayout>
)

interface ConfirmAddressTextProps {
  readonly showPrefills: boolean
  readonly onStartOver: () => void
}

const ConfirmAddressText = ({ showPrefills, onStartOver }: ConfirmAddressTextProps) => {
  if (showPrefills) {
    return <PrefillConfirmBanner onReset={onStartOver} />
  }

  return (
    <>
      <Text>Please verify that your property&rsquo;s name and address is accurate.</Text>
      <Box minH="16px" />
    </>
  )
}

const Container = styled(Box)`
  height: 100%;
`
const SmallTextStyled = styled(SmallText)`
  margin: 0;
  text-align: left;
  color: ${(props) => props.theme.colors.dusk};
`

const PropertyContainer = styled(Box)``

const Divider = styled(Box)`
  height: 2px;
  background-color: ${(props) => props.theme.colors.cloud};
  margin: 8px 0;
`
