import React, { useMemo, useState } from 'react'
import { Analytics } from '@genoa/analytics'
import { BillerGenericPortal } from '@genoa/domain'
import { CO_PORTAL_SELECTION, PORTAL_SELECTION } from '@genoa/screen-content'
import { Box, Flex, Skeleton, SkeletonCircle, Stack } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useContentOverhaul } from '../../../../hooks'
import { Headline2, InlineButton, SmallText, Text } from '../../../components'
import { TextInput } from '../../../components/Input'
import { BasePageLayout } from '../../../layouts'

const filterPortals = (portals: readonly BillerGenericPortal[], searchCriteria: string) => {
  return portals
    .slice()
    .sort((a, b) => (a as any).position - (b as any).position)
    .filter((x) => !searchCriteria || x.portal_name.toLowerCase().indexOf(searchCriteria.toLowerCase()) >= 0)
    .filter(({ portal_display_config: x }) => !!x.logo_url && !!x.background_color && !!x.login_url)
}

type RentPortalSelectionProps = {
  readonly onBack: () => unknown
  readonly onSelectPortal: (portal: BillerGenericPortal) => unknown
  readonly onPortalNotSeen: () => Promise<void>
  readonly portals?: readonly BillerGenericPortal[]
  readonly analyticsScreenName: Analytics.Screens
}

export const RentPortalSelection = (props: RentPortalSelectionProps) => {
  const [searchCriteria, setSearchCriteria] = useState<string>('')
  const { onboardingEnabled } = useContentOverhaul()
  const content = onboardingEnabled ? CO_PORTAL_SELECTION : PORTAL_SELECTION

  const portals = useMemo(() => filterPortals(props.portals ?? [], searchCriteria), [props.portals, searchCriteria])

  return (
    <BasePageLayout analyticsScreenName={props.analyticsScreenName} bottomPadding={false}>
      <Flex direction="column" h="100%">
        <Box flex="1" overflowY="auto">
          <Box minH={'30px'} />

          <Box>
            <Headline2 data-testid="RentPortalSelectionHeader">{content.HEADER}</Headline2>

            <Box minH={'24px'} />

            <Text data-testid="RentPortalSelectionBody">{content.BODY}</Text>
            <Box minH={'16px'} />
          </Box>
          <Box minH={'40px'} />

          <Box>
            <SmallText>{content.SEARCH_INPUT_LABEL}</SmallText>
            <Box minH={'4px'} />
            <TextInput
              onTextChange={setSearchCriteria}
              text={searchCriteria}
              placeholder={content.SEARCH_INPUT_PLACEHOLDER}
              testID="RentPortalSelectionSearchInput"
            />
          </Box>

          <PortalListContainer>
            {props.portals ? (
              portals.map((portal, index) => {
                return (
                  <PortalContainer
                    align="center"
                    justifyContent="center"
                    key={`portal-${portal.portal_name}`}
                    onClick={() => props.onSelectPortal(portal)}
                    data-testid={`RentPortalSelectionItem-${portal.portal_name}`}
                  >
                    <PortalContents align="center" justifyContent="center" flexDirection={'column'}>
                      <PortalLogo src={portal.portal_display_config.color_logo_url} />
                      <Box minH={'4px'} />
                      <SmallText fontWeight="bold" fontFamily={'RebrandDis'}>
                        {portal.display_name ?? portal.portal_name}
                      </SmallText>
                    </PortalContents>
                  </PortalContainer>
                )
              })
            ) : (
              <SkeletonPortalListItems />
            )}
          </PortalListContainer>
        </Box>
        <Box minH={'10px'} />
        <Flex justifyContent="center" p={8} bg="white" position="sticky" bottom={0}>
          <InlineButton onClick={props.onPortalNotSeen}>
            <Text fontWeight="bold" textAlign="center" fontFamily={'RebrandDis'}>
              <b>{content.NO_PORTAL}</b>
            </Text>
          </InlineButton>
        </Flex>
      </Flex>
    </BasePageLayout>
  )
}

const SkeletonPortalListItems = ({ length = 8 }: { length?: number }) => {
  return (
    <>
      {[...Array(length)].map((_, index) => (
        <SkeletonPortalListItem key={index} />
      ))}
    </>
  )
}

const SkeletonPortalListItem = (props: { key: string | number }) => (
  <PortalContainer align="center" justifyContent="center" key={props.key}>
    <Stack align="center">
      <SkeletonCircle size="12" />
      <Skeleton height="10px" width="100px" />
    </Stack>
  </PortalContainer>
)

const PortalListContainer = styled(Flex)`
  flex-direction: column;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 24px;
`
const PortalContainer = styled(Flex)`
  min-height: 80px;
  margin-bottom: 16px;
  border-radius: 16px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  padding: 16px;
  cursor: pointer;
`
const PortalContents = styled(Flex)``
const PortalLogo = styled('img')`
  max-width: 90%;
  height: 40px;
  background-size: contain;
`
