import React, { ReactNode } from 'react'
import { Analytics } from '@genoa/analytics'
import { VariantValue } from '@genoa/experiments'
import { ExperimentClient } from '@amplitude/experiment-js-client'

import { ConsentManagementProxy } from './consent-management'
import { EnhancedTrackingState } from './enhanced-tracking'
import { GTMContainerConfiguration } from './plugins/gtm'

export const CONTROL_VARIANT = 'control'
export const FLAG_ON = 'on'

export const AmplitudeContextValues: AmplitudeContextData = {
  experiment: null,
  experimentLoaded: false,
  enhancedTracking: {
    isEnabled: () => false,
    addAndGetAllSharedEventProperties: (_: Record<string, string>) => ({}),
    getAllSharedEventProperties: () => ({}),
  },
  getVariantPayload: <T>(variantId: string) => null,
  getVariantValue: (variantId: string) => undefined,
  getVariant: (variantId: string) => ({ value: undefined, payload: undefined }),
  setUserProperty: (propertyName: Analytics.UserProperties, propertyValue: any, setOnce?: boolean) => {},
  setUserId: (userId: string | null) => {},
  setDeviceId: (deviceId: string) => {},
  logEvent: (eventInput: string, eventProperties?: Record<string, any>) => {},
  refetchExperimentsForUser: (userId: string) => Promise.resolve({}) as Promise<ExperimentClient>,
  isFeatureFlagEnabled: (featureFlag: string) => false,
}

export type AmplitudeContextData = {
  experiment: ExperimentClient | null
  experimentLoaded: boolean
  enhancedTracking: EnhancedTrackingState
  consentManagement?: ConsentManagementProxy
  getVariantPayload: <T>(variantId: string) => T | null
  getVariantValue: (variantId: string) => string | undefined
  getVariant: <T>(variantId: string) => VariantValue
  setUserProperty: (propertyName: Analytics.UserProperties, propertyValue: any, setOnce?: boolean) => void
  setUserId(userId: string | null): void
  setDeviceId(deviceId: string): void
  logEvent(eventInput: string, eventProperties?: Record<string, any>): void
  refetchExperimentsForUser: (userId: string) => Promise<ExperimentClient | undefined>
  isFeatureFlagEnabled: (featureFlag: string) => boolean
}

export type AmplitudeProviderProps = {
  children?: ReactNode
  apiKey: string
  deploymentApiKey: string
  version?: string
  gtmConfig?: GTMContainerConfiguration
  consentManagementConfig?: {
    scriptSrc: string
  }
}
