import { BillerResidentLookupResponse } from '@genoa/domain'

export type BillerResidentLookupData = BillerResidentLookupResponse & { initialized: boolean }

export enum EmbedType {
  modal = 'modal',
}

export interface Embed {
  client: string
  isEmbed: boolean
  token: string
  type?: EmbedType
  origin?: string
}
