import { reducers as sharedReducers } from '@genoa/state-management'
import { combineReducers } from 'redux'

import { reducer as auth } from './auth'
import { reducer as customerStatus } from './flex2/customer-status'
import { reducer as customerWallet } from './flex2/customer-wallet'
import { reducer as embed } from './flex2/embed'
import { reducer as offerState } from './flex2/offer'
import { reducer as pricingOffer } from './flex2/pricing-offer'
import { reducer as repayNow } from './flex2/repay-now'
import { reducer as scheduleOptions } from './flex2/schedule-options'
import { rootApi } from './flexApi'
import { reducer as layout } from './layout'
import { reducer as onboarding } from './onboarding'

export const reducers = combineReducers({
  auth,
  layout,
  onboarding,
  offerState,
  pricingOffer,
  customerWallet,
  repayNow,
  customerStatus,
  scheduleOptions,
  embed,
  api: rootApi.reducer,
  ...sharedReducers,
})

export type RootState = ReturnType<typeof reducers>
