import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'

import { useModal } from '../../../../contexts'
import { ConnectBillerType, useConnectBiller, useHandleSetFlexAnywherePortal } from '../../../../hooks'
import { useAnalytics } from '../../../../providers'
import * as Routes from '../../../../routing/constants'
import { SmallText } from '../../../components'
import FlexAnywhereHowYouPay from './FlexAnywhereHowYouPay'

type FlexAnywhereHowYouPayContainerProps = {
  analyticsScreenName: Analytics.Screens
}

const FlexAnywhereHowYouPayContainer = (props: FlexAnywhereHowYouPayContainerProps) => {
  useHandleSetFlexAnywherePortal()
  const analytics = useAnalytics()
  const navigate = useNavigate()
  const handleGotIt = () => {
    analytics.logEvent(Analytics.Events.OON_HOW_YOU_PAY_CTA_CLICKED)
    navigate(Routes.Onboarding.RENT_AMOUNT)
  }
  const modal = useModal()

  const connectBillerType = ConnectBillerType.FLEX_ANYWHERE

  const renderGenericErrorModal = () => {
    modal.show({
      title: 'Whoops!',
      cta: 'Close',
      render: () => <SmallText>We ran into a technical problem. Try again in a few moments.</SmallText>,
    })
  }

  const { connecting, connectBiller } = useConnectBiller({
    componentName: 'V2RentPortalSignInContainer',
    onError: renderGenericErrorModal,
    onSamePropertyRelink: handleGotIt,
    onConnected: handleGotIt,
    connectBillerType: connectBillerType,
  })

  const onClickGotIt = () => {
    analytics.logEvent(Analytics.Events.OON_HOW_YOU_PAY_CTA_CLICKED)
    connectBiller()
  }

  const handleCantPayViaDebit = () => {
    analytics.logEvent(Analytics.Events.OON_HOW_YOU_PAY_NO_DEBIT_CLICKED)
    navigate(Routes.Onboarding.FLEX_ANYWHERE_WAITLIST)
  }

  return (
    <FlexAnywhereHowYouPay
      onClickGotIt={onClickGotIt}
      analyticsScreenName={props.analyticsScreenName}
      loading={connecting}
      onCantPayViaDebit={handleCantPayViaDebit}
    />
  )
}

export default FlexAnywhereHowYouPayContainer
