import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'

import * as Routes from '../../../../routing/constants'
import { V2RentPortalSignInContainer } from '../../common/property-linking/V2RentPortalSignInContainer'

type OnboardingRentPortalSignInContainerProps = {}

export const V2OnboardingRentPortalSignInContainer = (props: OnboardingRentPortalSignInContainerProps) => {
  const navigate = useNavigate()

  return (
    <V2RentPortalSignInContainer
      {...props}
      analyticsScreenName={Analytics.Screens.ONBOARDING_RENT_PORTAL_SIGNIN}
      analyticsEventForgotPasswordClicked={Analytics.Events.RENT_PORTAL_SIGNIN_FORGOT_PASSWORD_CLICKED}
      analyticsEventAttemptSignInYardi={Analytics.Events.DASHBOARD_RENT_PORTAL_SIGNIN_FOR_DIRECT_INTEGRATION_PROPERTY}
      analyticsEventBackClicked={Analytics.Events.RENT_PORTAL_SIGNIN_BACK_CLICKED}
      analyticsEventCTAClicked={Analytics.Events.RENT_PORTAL_SIGNIN_CTA_CLICKED}
      analyticsEventWaitingPortalConnection={Analytics.Events.RENT_PORTAL_SIGNIN_WAITING}
      analyticsEventStartOverClicked={Analytics.Events.RENT_PORTAL_SIGNIN_RESTART_WITH_NEW_ADDRESS_CLICKED}
      analyticsEventWhatIfNoPortalClicked={Analytics.Events.RENT_PORTAL_SIGNIN_WHAT_IF_NO_PORTAL_CLICKED}
      onRestart={() => {
        navigate(Routes.Onboarding.ADDRESS)
      }}
      onNext={() => navigate(Routes.Onboarding.RENT_AMOUNT)}
    />
  )
}
