import { IntegrationType, SLCType } from '@genoa/domain'

export const productTypeToTrackingValue = (productType?: SLCType) => {
  if (productType === undefined || productType === null) {
    return 'RENT_SPLIT_LEGACY'
  } else if (productType === SLCType.CLASSIC) {
    return 'SLC'
  }
  return SLCType[productType]
}

export const integrationTypeToTrackingValue = (
  integrationType: IntegrationType,
  billingIntegrationType?: string | null
) => {
  switch (integrationType) {
    case IntegrationType.YARDI:
    case IntegrationType.REALPAGE:
      return `${IntegrationType[IntegrationType.DIRECT_INTEGRATION]}_${IntegrationType[integrationType]}`
    case IntegrationType.DIRECT_INTEGRATION:
      if (billingIntegrationType !== undefined && billingIntegrationType !== null) {
        return `${IntegrationType[IntegrationType.DIRECT_INTEGRATION]}_${billingIntegrationType}`
      }
      return IntegrationType[IntegrationType.DIRECT_INTEGRATION]
    case IntegrationType.PORTAL:
    case IntegrationType.FLEX_ANYWHERE:
      return IntegrationType[integrationType]
    default:
      return integrationType
  }
}
