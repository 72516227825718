import React, { ReactNode, useEffect, useState } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { useIsInEmbedFrame } from '../../../hooks'
import { ClosePurpleCircle as ClosePurpleIcon } from '../../assets'
import { Headline2 } from '../Typography'
import {
  appearFromCenter,
  disappearInCenter,
  fadeIn,
  fadeInEmbed,
  fadeOut,
  fadeOutEmbed,
  slideDown,
  slideUp,
} from './animations'

const DEFAULT_ANIMATION_DURATION_MS = 300

type ModalProps = {
  visible: boolean
  canClose: boolean
  onClose: () => unknown
  title: string
  children: ReactNode
  hasCloseButton?: boolean
  testID?: string
  hideMobileBodyMargin?: boolean
}

type AnimationState = 'VISIBLE' | 'HIDDEN' | undefined

export const Modal = (props: ModalProps) => {
  const isInEmbedFrame = useIsInEmbedFrame()
  const [animationState, setAnimationState] = useState<AnimationState>(undefined)

  useEffect(() => {
    const shouldFinishAnimations = !props.visible && animationState

    shouldFinishAnimations ? setAnimationState(undefined) : setAnimationState('VISIBLE')
  }, [props.visible])

  useEffect(() => {
    if (animationState === 'HIDDEN') {
      setTimeout(() => {
        props.onClose && props.onClose()
      }, DEFAULT_ANIMATION_DURATION_MS)
    }
  }, [animationState])

  if (!props.visible) {
    return null
  }

  return (
    <Container data-testid={props.testID} alignItems="center" justifyContent="center">
      <Backdrop
        visible={props.visible}
        animationState={animationState}
        className="modal-backdrop"
        onClick={() => !!props.canClose && setAnimationState('HIDDEN')}
        isInEmbedFrame={isInEmbedFrame}
      />

      {!!animationState && (
        <Contents
          animationState={animationState}
          className="modal-contents"
          flexDirection="column"
          isInEmbedFrame={isInEmbedFrame}
        >
          {props.hasCloseButton && (
            <CloseIconContainer onClick={() => setAnimationState('HIDDEN')}>
              <ClosePurpleIcon />
            </CloseIconContainer>
          )}

          {!!props.title && (
            <TitleContainer className="modal-title">
              <Headline2 flex={1} data-testid="ModalTitle">
                {props.title}
              </Headline2>
            </TitleContainer>
          )}

          <BodyContainer
            flexDirection="column"
            isInEmbedFrame={isInEmbedFrame}
            justifyContent="flex-end"
            hideMobileBodyMargin={props.hideMobileBodyMargin}
          >
            {props.children}
          </BodyContainer>

          <Box h="20px"></Box>
        </Contents>
      )}
    </Container>
  )
}

interface AnimationProps {
  readonly animationState: AnimationState
  readonly isInEmbedFrame: boolean
}

const fullscreen = `
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
`

const Container = styled(Flex)`
  ${fullscreen}
`

const Backdrop = styled(Flex)<AnimationProps>`
  ${fullscreen}

  ${(props) =>
    props.animationState === 'VISIBLE' &&
    css`
      animation: ${props.isInEmbedFrame ? fadeInEmbed : fadeIn} ${DEFAULT_ANIMATION_DURATION_MS}ms ease forwards;
    `}

  ${(props) =>
    props.animationState === 'HIDDEN' &&
    css`
      animation: ${props.isInEmbedFrame ? fadeOutEmbed : fadeOut} ${DEFAULT_ANIMATION_DURATION_MS}ms ease forwards;
    `}
`

const Contents = styled(Flex)<AnimationProps>`
  background: ${(props) => props.theme.colors.background};
  min-height: 200px;
  max-height: 80%;
  min-width: ${(props) =>
    props.isInEmbedFrame ? props.theme.modalContents.embedWidth : props.theme.modalContents.minWidth};
  max-width: ${(props) =>
    props.isInEmbedFrame ? props.theme.modalContents.embedWidth : props.theme.modalContents.maxWidth};
  border-radius: ${(props) => props.theme.radii.modal};
  padding: 30px;
  z-index: 2;

  ${(props) => props.theme.media.phone`
    position: absolute;
    bottom: 0;
    min-width: 100vw;
    max-width: 100vw;
    max-height: 90%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 16px;
  `}

  ${(props) =>
    props.animationState === 'VISIBLE' &&
    css`
      animation: ${appearFromCenter} ${DEFAULT_ANIMATION_DURATION_MS}ms ease forwards;
    `}

  ${(props) =>
    props.animationState === 'HIDDEN' &&
    css`
      animation: ${disappearInCenter} ${DEFAULT_ANIMATION_DURATION_MS}ms ease forwards;
    `}

  ${(props) =>
    props.animationState === 'VISIBLE' &&
    props.theme.media.phone`
      animation: ${slideUp} ${DEFAULT_ANIMATION_DURATION_MS}ms ease forwards;
  `}

  ${(props) =>
    props.animationState === 'HIDDEN' &&
    props.theme.media.phone`
      animation: ${slideDown} ${DEFAULT_ANIMATION_DURATION_MS}ms ease forwards;
  `}
`

const TitleContainer = styled(Flex)`
  display: block;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  min-height: 30px;
`

const CloseIconContainer = styled(Box)`
  display: block;
  margin-left: auto;
  margin-right: 0;
`

interface BodyContainerProps {
  readonly hideMobileBodyMargin?: boolean
  readonly isInEmbedFrame: boolean
}

const BodyContainer = styled(Box)<BodyContainerProps>`
  display: block;
  margin-left: ${(props) => (props.isInEmbedFrame ? '10px' : '45px')};
  margin-right: ${(props) => (props.isInEmbedFrame ? '10px' : '45px')};
  margin-top: 20px;
  text-align: center;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 5px 0;

  ${(props) => props.theme.media.phone`
    max-height: 80%;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: ${props.hideMobileBodyMargin ? '0px' : '20px'};
  `}
`

type ModalActionsProps = {
  children: ReactNode
  flexDirection?: 'row' | 'column'
  testID?: string
  marginTop?: string
}

export const ModalActions = ({ children, flexDirection, marginTop }: ModalActionsProps) => (
  <Actions flexDirection={flexDirection} marginTop={marginTop}>
    {children}
  </Actions>
)

const Actions = styled(Flex)`
  margin-top: ${(props) => props.marginTop || '30px'};
  flex-direction: ${(props) => props.flexDirection || 'row'};
  align-items: stretch;
`
