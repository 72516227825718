import { useCallback } from 'react'
import { browserName, browserVersion, isBrowser, isMobile } from 'react-device-detect'
import { Analytics } from '@genoa/analytics'

import { useAuthState } from '../contexts'
import { setCoBrandingPropertyAction } from '../modules'
import { useAnalytics } from '../providers'
import { useReduxAction } from './use-redux-action'

const getDeviceType = (): string => {
  const version = `${browserName}@${browserVersion}`
  if (isBrowser) {
    return `browser/${version}`
  }
  if (isMobile) {
    return `mobile/${version}`
  }
  return `unknown/${version}`
}

export interface UTMParams {
  readonly utm_campaign: string
  readonly utm_source: string
  readonly utm_medium: string
}

export interface ApplicationParams extends UTMParams {
  readonly property_name?: string
  readonly amp_device_id?: string
}

const applicationParams = ['utm_source', 'utm_medium', 'utm_campaign', 'property_name', 'amp_device_id']
const urlParamsToApplicationParams = (urlParams: URLSearchParams): ApplicationParams => {
  return applicationParams.reduce((previous, current) => {
    return {
      ...previous,
      [current]: urlParams.get(current),
    }
  }, {}) as ApplicationParams
}

export const useTriggerApplicationOpen = () => {
  const analytics = useAnalytics()
  const authState = useAuthState()
  const setCoBrandingProperty = useReduxAction(setCoBrandingPropertyAction)

  const extractUrlParams = () => {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParamsToApplicationParams(urlParams)
  }

  const triggerApplicationOpen = useCallback(() => {
    const { utm_source, utm_medium, utm_campaign, property_name, amp_device_id } = extractUrlParams()
    const deviceType = getDeviceType()

    if (property_name) {
      setCoBrandingProperty(property_name)
    }
    if (amp_device_id) {
      analytics.setDeviceId(amp_device_id)
    }

    analytics.setUserProperty(Analytics.UserProperties.USERID, !authState.isAnonymous ? authState.uid : null)
    analytics.setUserProperty(Analytics.UserProperties.UTM_CAMPAIGN, utm_campaign)
    analytics.setUserProperty(Analytics.UserProperties.UTM_MEDIUM, utm_medium)
    analytics.setUserProperty(Analytics.UserProperties.UTM_SOURCE, utm_source)
    analytics.setUserProperty(Analytics.UserProperties.DEVICETYPE, deviceType)

    analytics.logEvent(Analytics.Events.APPLICATION_OPEN, { referrer_url: document?.referrer })
  }, [authState])

  return triggerApplicationOpen
}
