import { ReactNode, useCallback, useEffect, useState } from 'react'
import { Analytics } from '@genoa/analytics'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { HelpMenuActionsProvider } from '../../contexts'
import { useIsInEmbedFrame } from '../../hooks'
import { useNavigation } from '../../navigation'
import { useAnalytics } from '../../providers'
import { ClosePurpleCircle, Logo } from '../assets'
import { SecondaryButton, SecondaryRoundButton, SmallText } from '../components'
import { HelpMenu } from '../components/HelpMenu'

export const HEADER_HEIGHT_DESKTOP = 80
export const HEADER_HEIGHT_MOBILE = 44

export type BasePageLayoutProps = {
  children: ReactNode
  onBack?: () => unknown
  bg?: string
  analyticsScreenName?: Analytics.Screens
  analyticsScreenParams?: any
  hideHelpButton?: boolean
  coBrandingProperty?: string
  showLogInButton?: boolean
  showSignInButton?: boolean
  bottomPadding?: boolean
  actions?: {
    action: () => void
    label: string
  }[]
  isEmbedSignup?: boolean
}

export const BasePageLayout = (props: BasePageLayoutProps) => {
  const [helpOpen, setHelpOpen] = useState(false)
  const analytics = useAnalytics()
  const { navigateToLogIn, navigateToSignIn } = useNavigation()
  const isInEmbedFrame = useIsInEmbedFrame()

  useEffect(() => {
    if (props.analyticsScreenName) {
      analytics.logScreenView(props.analyticsScreenName, props.analyticsScreenParams)
    }
  }, [])

  const onClickFlexLogo = useCallback(() => {
    analytics.logEvent(Analytics.Events.HEADER_LOGO_CLICKED)
  }, [])

  const onCloseHelpModalSheet = useCallback(() => setHelpOpen(false), [])

  const onOpenHelpModalSheet = useCallback(() => setHelpOpen(true), [])

  const handleGoToLogIn = () => {
    analytics.logEvent(Analytics.Events.NAVBAR_LOGIN_CLICKED)
    navigateToLogIn()
  }

  const handleGoToSignIn = () => {
    analytics.logEvent(Analytics.Events.NAVBAR_SIGNIN_CLICKED)
    navigateToSignIn()
  }

  const closeEmbedModal = () => {
    window.parent.postMessage({ type: 'embed-close' }, '*')
  }

  return (
    <Layout className="base-page-layout">
      <NavigationBar className="navigation-bar">
        <LogoContainer onClick={onClickFlexLogo} className="navigation-bar-left">
          <Logo />
        </LogoContainer>

        <NavigationBarRight className="navigation-bar-right">
          {!props.hideHelpButton && (
            <SecondaryButton onClick={onOpenHelpModalSheet} testID="HelpMenu" variant={'help'}>
              Help
            </SecondaryButton>
          )}
          {props.showLogInButton && (
            <SecondaryRoundButton onClick={handleGoToLogIn} testID="LogIn" variant={'help'}>
              Log in
            </SecondaryRoundButton>
          )}
          {props.showSignInButton && (
            <SecondaryRoundButton onClick={handleGoToSignIn} testID="SignUp" variant={'help'}>
              Sign up
            </SecondaryRoundButton>
          )}
          {isInEmbedFrame && (
            <CloseIconContainer onClick={closeEmbedModal}>
              <ClosePurpleCircle />
            </CloseIconContainer>
          )}
        </NavigationBarRight>
      </NavigationBar>

      <Container
        className="base-page-layout-container"
        isInEmbedFrame={isInEmbedFrame}
        style={{ background: props.bg, paddingBottom: props.bottomPadding === false ? 0 : 30 }}
      >
        {props.coBrandingProperty && (
          <CobrandingBanner>
            <SmallText fontWeight="bold">👋 Hi, resident of {props.coBrandingProperty}</SmallText>
          </CobrandingBanner>
        )}
        <Contents
          className="base-page-layout-content"
          isEmbedSignup={!!props.isEmbedSignup}
          isInEmbedFrame={isInEmbedFrame}
        >
          {props.children}
        </Contents>
      </Container>

      <HelpMenuActionsProvider actions={props.actions || []}>
        <HelpMenu analyticsScreenName={props.analyticsScreenName} onClose={onCloseHelpModalSheet} visible={helpOpen} />
      </HelpMenuActionsProvider>
    </Layout>
  )
}

const CobrandingBanner = styled(Box)`
  padding: 8px 16px;
  width: 100%;
  text-align: center;
  background: ${(props) => props.theme.colors.softLilac};
`

const Layout = styled(Box)`
  position: fixed;
  left: 0;
  top: 0;
  max-width: 100vw;
  min-width: 100vw;
  max-height: 100%;
  min-height: 100%;
  overflow-x: hidden;
`
const NavigationBar = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100vw;
  min-width: 100vw;

  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  align-items: center;
  justify-content: space-between;

  height: 80px;
  padding: 0 36px;

  ${(props) => props.theme.media.phone`
    height: 44px;
    padding: 0 16px;
  `}
`

const Container = styled(Flex)<{ isInEmbedFrame: boolean }>`
  position: fixed;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  overflow-y: ${({ isInEmbedFrame }) => (isInEmbedFrame ? 'auto' : 'scroll')};

  min-height: calc(100% - 80px);
  max-height: calc(100% - 80px);
  margin-top: 80px;

  ${(props) => props.theme.media.phone`
    min-height: calc(100% - 44px);
    max-height: calc(100% - 44px);
    margin-top: 44px;
  `}
`

const LogoContainer = styled(Box)`
  height: 30px;
  width: 75px;

  ${(props) => props.theme.media.phone`
    height: 30px;
    width: 75px;
    margin-left: 12px;
  `}
`

const Contents = styled(Flex)<{ isEmbedSignup: boolean; isInEmbedFrame: boolean }>`
  width: ${({ isEmbedSignup, isInEmbedFrame }) => (isEmbedSignup && isInEmbedFrame ? '100%' : '480px')};
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
  padding: 0 ${({ isEmbedSignup, isInEmbedFrame }) => (isInEmbedFrame && !isEmbedSignup ? '10px' : '0')};

  ${({ isEmbedSignup, isInEmbedFrame, theme }) => theme.media.phone`
    width: ${isEmbedSignup && isInEmbedFrame ? '100%' : '320px'};
  `}
`

const NavigationBarRight = styled(Box)`
  display: flex;
`

const CloseIconContainer = styled(Box)`
  cursor: pointer;
  margin-left: ${({ theme }) => theme.fixedSizes.spacing_75};
`
