import { OnboardingContentOverhaulExperiment, OnboardingContentOverhaulVariantValues } from '@genoa/experiments'

import { useSecureLineOfCredit } from '../secure-line-of-credit'
import { useExperimentVariant } from '../use-experiment-variant'

type ContentOverhaul = {
  inOnboardingExperiment: boolean
  onboardingEnabled: boolean
}

export const useContentOverhaul = (): ContentOverhaul => {
  const { isFlagEnabledForCreditBuilder, isEnabledForSLC } = useSecureLineOfCredit()
  const onboardingVariant = useExperimentVariant(OnboardingContentOverhaulExperiment)
  const onboardingTreatment = onboardingVariant === OnboardingContentOverhaulVariantValues.Treatment

  return {
    inOnboardingExperiment: !!onboardingVariant && !isFlagEnabledForCreditBuilder && !isEnabledForSLC,
    onboardingEnabled: onboardingTreatment && !isFlagEnabledForCreditBuilder && !isEnabledForSLC,
  }
}
