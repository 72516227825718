import React from 'react'
import { Button } from '@chakra-ui/react'

type InlineButtonProps = {
  children: React.ReactNode
  disabled?: boolean
  onClick: () => unknown
  testID?: string
  fontSize?: string
  color?: string
}

export const InlineButton = (props: InlineButtonProps) => {
  return (
    <Button
      textColor={props.color}
      variant="inline"
      onClick={props.onClick}
      isDisabled={props.disabled}
      display={'inline-block'}
      maxWidth={'100%'}
      whiteSpace={'normal'}
      textAlign={'left'}
      data-testid={props.testID}
      _focusVisible={{
        boxShadow: '0px 0px 0px 2px white',
        outline: '2px solid #1C53BA',
        outlineOffset: '2px',
      }}
      {...(props.fontSize && { fontSize: props.fontSize })}
    >
      {props.children}
    </Button>
  )
}
