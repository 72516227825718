import { Agreement } from '@genoa/domain'
import { CREDIT_BUILDER_AGREEMENTS_MODAL_CONTENT as content } from '@genoa/screen-content'
import { Box, Flex, useTheme } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useAgreementLink } from '../../../../../hooks'
import { Gap, Headline2, InlineButton, LinkText, Modal, PrimaryButton, SmallText } from '../../../../components'

export interface CreditBuilderAgreementsModalProps {
  readonly visible: boolean
  readonly agreements: Agreement[]
  readonly loading: boolean
  readonly onClose: () => void
  readonly onAccept: () => void
}

export const CreditBuilderAgreementsModal = ({
  visible,
  agreements,
  loading,
  onClose,
  onAccept,
}: CreditBuilderAgreementsModalProps) => {
  const theme = useTheme()
  const handleAgreementClick = useAgreementLink()

  return (
    <Modal visible={visible} title={''} testID="CreditBuilderAgreementsModal" canClose={true} onClose={onClose}>
      <ContentContainer>
        <TitleContainer>
          <Headline2>{content.HEADER}</Headline2>
          <SmallText>{content.BODY}</SmallText>
        </TitleContainer>
        <AgreementsContainer>
          <SmallText fontWeight="bold">{content.SUB_HEADER}</SmallText>
          <Gap size="spacing_50" />
          {agreements.map((agreement, index) => (
            <Flex key={`agreement-${index}`}>
              <StyledListItem>
                <SmallText fontWeight="bold">
                  <InlineButton onClick={() => handleAgreementClick(agreement)} color={theme.colors.jewelPurple}>
                    {agreement.agreement_type_name}
                  </InlineButton>
                </SmallText>
              </StyledListItem>
            </Flex>
          ))}
        </AgreementsContainer>

        <GoBackButton fontWeight="bold" onClick={onClose}>
          {content.BACK}
        </GoBackButton>
        <PrimaryButton onClick={onAccept} disabled={loading} processing={loading}>
          {content.CTA}
        </PrimaryButton>
      </ContentContainer>
    </Modal>
  )
}

const ContentContainer = styled(Flex)`
  flex-direction: column;
  gap: ${(props) => props.theme.fixedSizes.spacing_200};
`

const TitleContainer = styled(Flex)`
  flex-direction: column;
  gap: ${(props) => props.theme.fixedSizes.spacing_100};
`

const AgreementsContainer = styled(Flex)`
  flex-direction: column;
  align-self: center;
  justify-content: center;
`

const StyledListItem = styled.li`
  color: ${(props) => props.theme.colors.jewelPurple};
`

const GoBackButton = styled(LinkText)`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-family: ${(props) => props.theme.fonts.heading};
  color: ${(props) => props.theme.colors.jewelPurple};
  font-size: ${(props) => props.theme.fontSizes.button};
`
