import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { EMBED_PROPERTY_MISMATCH as content } from '@genoa/screen-content'
import { Flex, Link } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import * as Routes from '../../../../../../routing/constants'
import { ChevronLeftPurple, HeroPhone } from '../../../../../assets'
import { AppStore, Gap, Headline2, InlineButton, LinkText, SmallText, Text } from '../../../../../components'
import { BasePageLayout } from '../../../../../layouts'

export const EmbedPropertyMismatch = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const goBack = () => {
    navigate(Routes.Embed.RELINK_DIRECT_INTEGRATION_CONFIRMATION)
  }
  return (
    <BasePageLayout analyticsScreenName={Analytics.Screens.EMBED_PROPERTY_MISMATCH}>
      <BackButton>
        <InlineButton
          onClick={goBack}
          color={theme.colors.jewelPurple}
          testID="DirectIntegrationConfirmAddressStartOverButton"
        >
          <Flex alignItems="center">
            <ChevronLeftPurple height={theme.fixedSizes.md} />
            <SmallText color={theme.colors.jewelPurple} marginLeft={theme.fixedSizes.spacing_50}>
              {content.BACK}
            </SmallText>
          </Flex>
        </InlineButton>
      </BackButton>
      <Container>
        <Gap size="spacing_200" />
        <HeroPhone />
        <Gap size="spacing_200" />
        <Headline2>{content.HEADER}</Headline2>
        <Gap size="spacing_100" />
        <SmallText textAlign="center">{content.BODY_1}</SmallText>
        <Gap size="spacing_200" />
        <SmallText fontWeight="bold" textAlign="center">
          {content.BODY_2}
        </SmallText>
        <Gap size="spacing_200" />
        <AppStore />
      </Container>
    </BasePageLayout>
  )
}

const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
`
const BackButton = styled(Flex)`
  padding-top: ${({ theme }) => theme.fixedSizes.spacing_100};
`
